import React from 'react';
import Helmet from 'react-helmet';

const Metatags = (props) => {

  const { title, description, url, base } = props;

  return (
    <Helmet>
      <html lang="en"/>
      <title>{title}</title>
      <meta name='title' content={title}/>

      <meta name='description' content={description}/>

      {url && <meta property='og:url' content={url}/>}


      <meta property='og:image' content={base + '/share.png'}/>

      <meta property=' og:image:secure_url' content={base + '/share.png'}/>

      <meta property='og:title' content={title}/>
      <meta property='og:description' content={description}/>

      <meta property='og:image:width' content='1200'/>

      <meta property='og:image:height' content='630'/>

      <meta property='og:locale' content='en'/>

      <meta name='twitter:title' content={title}/>

      <meta name='twitter:description' content={description}/>
      <meta name='twitter:card' content='summary_large_image'/>

      && <meta name='twitter:image' content={base + '/share.png'}/>
    </Helmet>
  );
};

export default Metatags;
