import React from 'react';
import { graphql } from 'gatsby';
import { Button, Icon, Tag } from 'antd';
import get from 'lodash/get';
import heroStyles from '../components/hero.module.css';
import MetaTags from '../components/metatags';

import '../components/base.css';
import './blog-post.module.css';

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: '',
      base: ''
    };
  }

  componentDidMount() {
    this.setState({
      url: window.location.href,
      base: window.location.origin
    });
  }

  render() {
    const post = get(this.props, 'data.contentfulBlogPost');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const description = post.description.childMarkdownRemark.html.replace(`<p>`, ``).replace(`</p>`, ``);

    return (
      <div style={{ background: '#fff' }}>
        <MetaTags
          title={post.title}
          description={description}
          url={this.state.url}
          base={this.state.base}
        />
        <div className={heroStyles.hero}>
          <div className={heroStyles.metaData}>
            <a href="javascript:history.back()">
              <Button type="link" shape="circle" icon="arrow-left" ghost/>
            </a>
            <Tag color="#19a8e0">
              {post.publishDate}
            </Tag>
          </div>
          <h1 className="section-headline">{post.title}</h1>
          <Icon type="down" style={{
            fontSize: '1.5em',
            color: '#4a4a4a',
            visibility: 'hidden'
          }}/>
        </div>
        <div className={heroStyles.socialShare}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${this.state.url}`}
            target="_blank">
            <Button type="primary" shape="circle" theme="twoTone" size="large"
                    className={heroStyles.primaryButton}>
              <Icon type="facebook" theme="filled"/>
            </Button>
          </a>
          <a href={`https://twitter.com/home?status=${this.state.url}`}
             target="_blank">
            <Button type="primary" shape="circle" theme="twoTone" size="large"
                    className={heroStyles.primaryButton}>
              <Icon type="twitter"/>
            </Button>
          </a>
          <a

            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              this.state.url)}`}
            target="_blank">
            <Button type="primary" shape="circle" theme="twoTone" size="large"
                    className={heroStyles.primaryButton}>
              <Icon type="linkedin" theme="filled"/>
            </Button>
          </a>
          <a href={`mailto:info@example.com?&subject=&body=${this.state.url}`}
             target="_blank">
            <Button type="primary" shape="circle" theme="twoTone" size="large"
                    className={heroStyles.primaryButton}>
              <Icon type="mail" theme="filled"/>
            </Button>
          </a>
        </div>
        <div className="blog-wrapper">
          <div
            dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html
            }}
          />
        </div>
      </div>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      body {
        childMarkdownRemark {
          html
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
